import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/src/components/layouts/MDXLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Effective date: January 7, 2021`}</p>
    <h2>{`Definitions`}</h2>
    <br />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`An "Account" represents your legal relationship with CounterSet (Jaupana Pty Ltd). A "User Account" represents an individual User's authorization to log in and use the Service.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The “Agreement” refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Service” or the "Terms") and all other operating rules, policies (including the CounterSet Privacy Policy, available at `}<a parentName="p" {...{
            "href": "https://www.counterset.com/privacy.html"
          }}>{`https://www.counterset.com/privacy.html`}</a>{`) and procedures that we may publish from time to time on the Website.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`“Content” refers to content featured or displayed through the Website, including without limitation code, text, data, articles, images, photographs, graphics, software, applications, packages, designs, features, and other materials that are available on the Website or otherwise available through the Service. "Content" also includes Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. "Your Content" is Content that you create or own.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`"CounterSet,” “We,” and “Us” refer to Jaupana Pty Ltd., as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The “Service” refers to the applications, software, products, and services provided by CounterSet.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`“The User,” “You,” and “Your” refer to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the Account; or that directs the use of the Account in the performance of its functions. A User must be at least 13 years of age.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The “Website” refers to CounterSet’s website located at counterset.com, and all content, services, and products provided by CounterSet at or through the Website. It also refers to CounterSet-owned subdomains of counterset.com`}</p>
      </li>
    </ol>
    <h2>{`Account Terms`}</h2>
    <br />
    <ol>
      <li parentName="ol">{`Account Controls`}</li>
    </ol>
    <p>{`Users. Subject to these Terms, you retain ultimate administrative control over your User Account and the Content within it.`}</p>
    <p>{`Organizations. The "owner" of an Organization that was created under these Terms has ultimate administrative control over that Organization and the Content within it. Within the Service, an owner can manage User access to the Organization’s data and projects. An Organization may have multiple owners, but there must be at least one User Account designated as an owner of an Organization. If you are the owner of an Organization under these Terms, we consider you responsible for the actions that are performed on or through that Organization.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Required Information`}</li>
    </ol>
    <p>{`You must provide a valid email address in order to complete the signup process. Any other information requested, such as your real name, is optional, unless you are accepting these terms on behalf of a legal entity (in which case we need more information about the legal entity) or if you opt for a paid Account, in which case additional information will be necessary for billing purposes.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Account Requirements`}</li>
    </ol>
    <p>{`We have a few simple rules for User Accounts on CounterSet's Service.`}</p>
    <p>{`You must be a human to create an Account. Accounts registered by "bots" or other automated methods are not permitted. We do permit machine accounts:`}</p>
    <p>{`A machine account is an Account set up by an individual human who accepts the Terms on behalf of the Account, provides a valid email address, and is responsible for its actions. A machine account is used exclusively for performing automated tasks. Multiple users may direct the actions of a machine account, but the owner of the Account is ultimately responsible for the machine's actions. You may maintain no more than one free machine account in addition to your free User Account.`}</p>
    <p>{`One person or legal entity may maintain no more than one free Account (if you choose to control a machine account as well, that's fine, but it can only be used for running a machine).`}</p>
    <p>{`You must be age 13 or older. CounterSet does not target our Service to children under 13, and we do not permit any Users under 13 on our Service. If we learn of any User under the age of 13, we will terminate that User’s Account immediately. If you are a resident of a country outside Australia, your country’s minimum age may be older; in such a case, you are responsible for complying with your country’s laws.`}</p>
    <p>{`You may not use CounterSet in violation of export control or sanctions laws of Australia or any other applicable jurisdiction.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`User Account Security`}</li>
    </ol>
    <p>{`You are responsible for keeping your Account secure while you use our Service.`}</p>
    <p>{`You are responsible for all content created and/or uploaded and activity that occurs under your Account (even when content is created and/or uploaded by others who have Accounts under your Account).
You are responsible for maintaining the security of your Account and password. CounterSet cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
You will promptly notify CounterSet if you become aware of any unauthorized use of, or access to, our Service through your Account, including any unauthorized use of your password or Account.`}</p>
    <h2>{`Acceptable Use`}</h2>
    <br />
    <p>{`Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws, export control or sanctions laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.`}</p>
    <h2>{`User-Generated Content`}</h2>
    <br />
    <ol>
      <li parentName="ol">{`Responsibility for User-Generated Content`}</li>
    </ol>
    <p>{`You may create or upload User-Generated Content while using the Service. You are solely responsible for the content of, and for any harm resulting from, any User-Generated Content that you post, upload, link to or otherwise make available via the Service, regardless of the form of that Content. We are not responsible for any public display or misuse of your User-Generated Content.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`CounterSet May Remove Content`}</li>
    </ol>
    <p>{`We have the right to refuse or remove any User-Generated Content that, at our sole discretion, violates any laws or CounterSet terms or policies.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Ownership of Content`}</li>
    </ol>
    <p>{`You retain ownership of and responsibility for Your Content. If you're posting anything you did not create yourself or do not own the rights to, you agree that you are responsible for any Content you post; that you will only submit Content that you have the right to post; and that you will fully comply with any third party licenses relating to Content you post.`}</p>
    <p>{`Notwithstanding anything to the contrary, CounterSet shall have the right to collect and analyze data and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning User-Generated Content and data derived therefrom), and CounterSet will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other CounterSet offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business.  No rights or licenses are granted except as expressly set forth herein.   `}</p>
    <h2>{`Copyright Infringement and DMCA Policy`}</h2>
    <br />
    <p>{`If you believe that content on our website violates your copyright, please contact us in accordance with our Digital Millennium Copyright Act Policy. If you are a copyright owner and you believe that content on CounterSet violates your rights, please contact us via email at `}<a parentName="p" {...{
        "href": "mailto:legal@counterset.com."
      }}>{`legal@counterset.com.`}</a>{` There may be legal consequences for sending a false or frivolous takedown notice. Before sending a takedown request, you must consider legal uses such as fair use and licensed uses.`}</p>
    <h2>{`Intellectual Property Notice`}</h2>
    <br />
    <p>{`CounterSet and our licensors, vendors, agents, and/or our content providers retain ownership of all intellectual property rights of any kind related to the Website and Service. We reserve all rights that are not expressly granted to you under this Agreement or by law. The look and feel of the Website and Service is copyright © Jaupana Pty ltd. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML/CSS, Javascript, or visual design elements or concepts without express written permission from CounterSet.`}</p>
    <h2>{`API Terms`}</h2>
    <br />
    <p>{`Abuse or excessively frequent requests to CounterSet via the API may result in the temporary or permanent suspension of your Account's access to the API. CounterSet, at our sole discretion, will determine abuse or excessive usage of the API. We will make a reasonable attempt to warn you via email prior to suspension.`}</p>
    <p>{`You may not share API tokens to exceed CounterSet's rate limitations.`}</p>
    <p>{`All use of the CounterSet API is subject to these Terms of Service and the CounterSet Privacy Policy.`}</p>
    <h2>{`Payment`}</h2>
    <br />
    <ol>
      <li parentName="ol">{`Pricing`}</li>
    </ol>
    <p>{`Our pricing and payment terms are available at `}<a parentName="p" {...{
        "href": "https://www.counterset.com/pricing"
      }}>{`https://www.counterset.com/pricing`}</a>{`. If you agree to a subscription price, that will remain your price for the duration of the payment term; however, prices are subject to change at the end of a payment term.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Upgrades, Downgrades, and Changes`}</li>
    </ol>
    <p>{`We will immediately bill you when you upgrade from the free plan to any paying plan.
If you change from a monthly billing plan to a yearly billing plan, CounterSet will bill you for a full year at the next monthly billing date.
If you upgrade to a higher level of service, we will bill you for the upgraded plan immediately.`}</p>
    <p>{`You may change your level of service at any time by choosing a plan option or going into your Billing settings. If you choose to downgrade your Account, you may lose access to Content, features, or capacity of your Account. Please see our section on Cancellation for information on getting a copy of that Content.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Billing Schedule; No Refunds`}</p>
        <p parentName="li">{`3.1 Payment Based on Plan
For monthly or yearly payment plans, the Service is billed in advance on a monthly or yearly basis respectively and is non-refundable. There will be no refunds or credits for partial months of service, downgrade refunds, or refunds for months unused with an open Account; however, the service will remain active for the length of the paid billing period.`}</p>
        <p parentName="li">{`3.2 Payment Based on Usage
Some Service features are billed based on your usage. A limited quantity of these Service features may be included in your plan for a limited term without additional charge. If you choose to purchase paid Service features beyond the quantity included in your plan, you pay for those Service features based on your actual usage in the preceding month. Monthly payment for these purchases will be charged on a periodic basis in arrears.`}</p>
        <p parentName="li">{`3.3 Invoicing For invoiced Users
User agrees to pay the fees in full, up front without deduction or setoff of any kind, in U.S. Dollars. User must pay the fees within thirty (30) days of the CounterSet invoice date. Amounts payable under this Agreement are non-refundable, except as otherwise provided in this Agreement. If User fails to pay any fees on time, CounterSet reserves the right, in addition to taking any other action at law or equity, to (i) charge interest on past due amounts at 1.0% per month or the highest interest rate allowed by law, whichever is less, and to charge all expenses of recovery, and (ii) terminate the applicable order form. User is solely responsible for all taxes, fees, duties and governmental assessments (except for taxes based on CounterSet's net income) that are imposed or become due in connection with this Agreement.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Authorization`}</p>
      </li>
    </ol>
    <p>{`By agreeing to these Terms, you are giving us permission to charge your on-file credit card, or other approved methods of payment for fees that you authorize for CounterSet.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Responsibility for Payment`}</li>
    </ol>
    <p>{`You are responsible for all fees, including taxes, associated with your use of the Service. By using the Service, you agree to pay CounterSet any charge incurred in connection with your use of the Service. If you dispute the matter, contact CounterSet Support. You are responsible for providing us with a valid means of payment for paid Accounts. Free Accounts are not required to provide payment information.`}</p>
    <h2>{`Cancellation and Termination`}</h2>
    <br />
    <ol>
      <li parentName="ol">{`Account Cancellation`}</li>
    </ol>
    <p>{`It is your responsibility to properly cancel your Account with CounterSet. To initiate cancellation of your Account you must email us at `}<a parentName="p" {...{
        "href": "mailto:billing@counterset.com"
      }}>{`billing@counterset.com`}</a>{` using the email address associated with your Account.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Upon Cancellation`}</li>
    </ol>
    <p>{`We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your full profile and the Content of your repositories within 90 days of cancellation or termination (though some information may remain in encrypted backups). This information can not be recovered once your Account is cancelled.`}</p>
    <p>{`Upon request, we will make a reasonable effort to provide an Account owner with a copy of your lawful, non-infringing Account contents after Account cancellation, termination, or downgrade. You must make this request within 90 days of cancellation, termination, or downgrade.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`CounterSet May Terminate`}</li>
    </ol>
    <p>{`CounterSet has the right to suspend or terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. CounterSet reserves the right to refuse service to anyone for any reason at any time.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Survival`}</li>
    </ol>
    <p>{`All provisions of this Agreement which, by their nature, should survive termination will survive termination — including, without limitation: ownership provisions, warranty disclaimers, indemnity, and limitations of liability.`}</p>
    <h2>{`Communications with CounterSet`}</h2>
    <br />
    <ol>
      <li parentName="ol">{`Electronic Communication Required`}</li>
    </ol>
    <p>{`For contractual purposes, you (1) consent to receive communications from us in an electronic form via the email address you have submitted or via the Service; and (2) agree that all Terms of Service, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that those communications would satisfy if they were on paper. This section does not affect your non-waivable rights.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Legal Notice to CounterSet Must Be in Writing`}</li>
    </ol>
    <p>{`Communications made through email will not constitute legal notice to CounterSet or any of its officers, employees, agents or representatives in any situation where notice to CounterSet is required by contract or any law or regulation. Legal notice to CounterSet must be in writing and served on CounterSet's office address.`}</p>
    <h2>{`Disclaimer of Warranties`}</h2>
    <br />
    <p>{`CounterSet provides the Website and the Service “as is” and “as available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Website and the Service including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.`}</p>
    <p>{`CounterSet does not warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or error-free; that the information provided through the Service is accurate, reliable or correct; that any defects or errors will be corrected; that the Service will be available at any particular time or location; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.`}</p>
    <h2>{`Limitation of Liability`}</h2>
    <br />
    <p>{`You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from`}</p>
    <ul>
      <li parentName="ul">{`the use, disclosure, or display of your User-Generated Content;`}</li>
      <li parentName="ul">{`your use or inability to use the Service;`}</li>
      <li parentName="ul">{`any modification, price change, suspension or discontinuance of the Service;`}</li>
      <li parentName="ul">{`the Service generally or the software or systems that make the Service available;`}</li>
      <li parentName="ul">{`unauthorized access to or alterations of your transmissions or data;`}</li>
      <li parentName="ul">{`statements or conduct of any third party on the Service;`}</li>
      <li parentName="ul">{`any other user interactions that you input or receive through your use of the Service; or`}</li>
      <li parentName="ul">{`any other matter relating to the Service.`}</li>
    </ul>
    <p>{`Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control.`}</p>
    <h2>{`Release and Indemnification`}</h2>
    <br />
    <p>{`If you have a dispute with one or more Users, you agree to release CounterSet from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.`}</p>
    <p>{`You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses, including attorneys’ fees, arising out of your use of the Website and the Service, including but not limited to your violation of this Agreement, provided that CounterSet (1) promptly gives you written notice of the claim, demand, suit or proceeding; (2) gives you sole control of the defense and settlement of the claim, demand, suit or proceeding (provided that you may not settle any claim, demand, suit or proceeding unless the settlement unconditionally releases CounterSet of all liability); and (3) provides to you all reasonable assistance, at your expense.`}</p>
    <h2>{`Changes to These Terms`}</h2>
    <br />
    <p>{`We reserve the right, at our sole discretion, to amend these Terms of Service at any time and will update these Terms of Service in the event of any such amendments. We will notify our Users of material changes to this Agreement, such as price increases, at least 30 days prior to the change taking effect by posting a notice on our Website or sending email to the primary email address specified in your CounterSet account. Customer's continued use of the Service after those 30 days constitutes agreement to those revisions of this Agreement. For any other modifications, your continued use of the Website constitutes agreement to our revisions of these Terms of Service.`}</p>
    <p>{`We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website (or any part of it) with or without notice.`}</p>
    <h2>{`Miscellaneous`}</h2>
    <br />
    <ol>
      <li parentName="ol">{`Governing Law`}</li>
    </ol>
    <p>{`Except to the extent applicable law provides otherwise, this Agreement between you and CounterSet and any access to or use of the Website or the Service are governed by the laws of Australia and the laws of the State of South Australia, without regard to conflict of law provisions. You and CounterSet agree to submit to the exclusive jurisdiction and venue of the courts located in South Australia.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Non-Assignability`}</li>
    </ol>
    <p>{`CounterSet may assign or delegate these Terms of Service and/or the CounterSet Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without our prior written consent, and any unauthorized assignment and delegation by you is void.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Section Headings and Summaries`}</li>
    </ol>
    <p>{`Throughout this Agreement, each section includes titles and brief summaries of the following terms and conditions. These section titles and brief summaries are not legally binding.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Severability, No Waiver, and Survival`}</li>
    </ol>
    <p>{`If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on the part of CounterSet to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Complaints and Enquiries`}</li>
    </ol>
    <p>{`If you have any queries or complaints regarding these Terms of Service please contact us at:`}</p>
    <p>{`Jaupana Pty Ltd.
L3, 9 Cavenagh St.
Darwin NT 0800
Australia
`}<a parentName="p" {...{
        "href": "mailto:legal@counterset.com"
      }}>{`legal@counterset.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      